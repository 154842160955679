let genres = [
  {key: "1", value: "Pop"},
  {key: "2", value: "Rock"},
  {key: "3", value: "Hip-Hop"},
  {key: "4", value: "Electronic"},
  {key: "5", value: "Jazz"},
  {key: "6", value: "Blues"},
  {key: "7", value: "Classical"},
  {key: "8", value: "Country"},
  {key: "9", value: "R&B"},
  {key: "10", value: "Reggae"},
  {key: "11", value: "Folk"},
  {key: "12", value: "Metal"},
  {key: "13", value: "Punk"},
  {key: "14", value: "Alternative"},
  {key: "15", value: "Funk"},
  {key: "16", value: "Soul"},
  {key: "17", value: "Rap"},
  {key: "18", value: "Indie"},
  {key: "19", value: "Techno"},
  {key: "20", value: "House"},
  {key: "21", value: "Dubstep"},
  {key: "22", value: "Ska"},
  {key: "23", value: "Gospel"},
  {key: "24", value: "Latin"},
  {key: "25", value: "World Music"},
  {key: "26", value: "Dancehall"},
  {key: "27", value: "Grunge"},
  {key: "28", value: "Ambient"},
  {key: "29", value: "New Age"},
  {key: "30", value: "Experimental"},
  {key: "31", value: "Bollywood"},
  {key: "32", value: "J-Pop"},
  {key: "33", value: "K-Pop"},
  {key: "34", value: "Flamenco"},
  {key: "35", value: "Salsa"},
  {key: "36", value: "Bossa Nova"},
  {key: "37", value: "Hip-Life"},
  {key: "38", value: "Cumbia"},
  {key: "39", value: "Tango"},
  {key: "40", value: "Bhangra"},
  {key: "41", value: "Reggaeton"},
  {key: "42", value: "Celtic"},
  {key: "43", value: "Afrobeat"},
  {key: "44", value: "Rockabilly"},
  {key: "45", value: "Bluegrass"},
  {key: "46", value: "Psychedelic"},
  {key: "47", value: "Trance"},
  {key: "48", value: "Sufi Music"},
  {key: "49", value: "Mariachi"},
  {key: "50", value: "Hardstyle"},
];

export default genres;
